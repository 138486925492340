@import url("~minireset.css/minireset.css");

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/*
Some resets so headings can inherit weight from their parent containers.
See heading usage in this project for details.
Look into moving into the design system
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
}

html {
  scroll-behavior: smooth;
}

.fieldBreakSm {
  flex-basis: 100%;
  height: 0;
}

select::-ms-expand {
  display: none;
}

@screen md {
  .Date,
  .Years,
  .Currency {
    width: 17.5rem;
  }

  .Percent {
    width: 7rem;
  }

  .fieldBreakSm {
    display: none;
  }

  .headerIconWidth {
    width: 78px;
  }
}

@media (min-width: 80em) {
  .confirmationLink {
    margin-left: 4.25rem;
    margin-right: 4.25rem;
  }
}

/* Some specific styles that aren't reusable */
.fixedFieldS {
  width: 5rem;
}

.fixedFieldM {
  width: 10.75rem;
}

/* Sticky Progress Bar */
.ProgressBar {
  top: 3rem;
}
.progressBarWithStickyHeader {
  top: 14.5rem;
}

.checkbox--error input + label:before {
  @apply border-tangerine;
}

.bg-tangerine-50 {
  @apply bg-tangerine;
  --tw-bg-opacity: 0.5;
}

.bg-seafoam-20 {
  @apply bg-seafoam;
  --tw-bg-opacity: 0.2;
}

.Imperial {
  width: 3.625rem;
}

.Metric {
  width: 5.5rem;
}

.link:hover {
  font-weight: bold;
}

div[role="button"]:hover {
  outline: none;
}

.read-only-banner {
  background-color: #e99;
}

.active-link,
.tab-link:hover {
  @apply text-just-black border-b-4 border-coral pb-1 font-bold;
}

.CookieDeclarationIntro {
  @apply text-base;
}

.CookieDeclarationDialogText,
.CookieDeclarationIntro > br:nth-child(odd) {
  @apply hidden;
}

.CookieDeclaration a {
  @apply link;
}

.CookieDeclarationTable {
  @apply !mb-0 overflow-x-scroll;
}

.CookieDeclaration tr > .CookieDeclarationTableCell {
  min-width: 150rem;
}

/* By some reason Cookiebot decides to apply lowercase for Provider column, this style fixes it. */
.CookieDeclaration tr > .CookieDeclarationTableCell:nth-child(2):first-letter {
  @apply uppercase;
}

#CookieDeclarationUserStatusLabel {
  @apply bg-white p-4 rounded shadow-md w-[90vw];
}

#CookieDeclarationChangeConsent {
  @apply mt-2;
}

.CookieDeclarationType {
  @apply !p-4 !border-0 bg-white rounded shadow-md w-[90vw];
}

@screen md {
  #CookieDeclarationUserStatusLabel,
  .CookieDeclarationType {
    @apply w-full;
  }
}
